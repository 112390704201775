$textcolor: #DBAFA0;
$bgcolor: #332941;
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap');

.navbar {
  background-color: $bgcolor;
  color: $textcolor;
  font-family: 'JetBrains Mono', monospace;
}

