@tailwind base;
@tailwind components;
@tailwind utilities;


$textcolor: white;
$highlighttext: #DBAFA0;
$bgcolor: #332941;
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $bgcolor;
  color: $textcolor;
}

html {
  background-color: $bgcolor  !important;
  color: $textcolor;
  font-family: 'JetBrains Mono', monospace;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



.defaulttheme {
    background-color: $bgcolor;
    color: $textcolor;
    font-family: 'JetBrains Mono', monospace;
}

.highlighttext {
    color: $highlighttext;
}
.reversehighlighttext {
    color: $bgcolor;

}

.button-positive{
    background-color: $highlighttext;
    color: $bgcolor;
    &:disabled {
      background-color: rgba($highlighttext, 0.6);
  }
}